.sucursales {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    padding: 100px 0;
    margin-top: -390px;
    position: relative;
    z-index: 1;
}

.container {
    max-width: 1080px;
    width: 100vw;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.map {
    width: 100%;
    height: 465px;
}

.mapInner {
    width: 100%;
    border: 0;
    height: 456px;
}

.filters {
    display: flex;
    list-style: none;
    padding: 0;
    justify-content: flex-start;
}

.filter {
    font-size: 18px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
    color: #e6e6e6;
    cursor: pointer;
}

.filter svg,
.filter__active svg {
    font-size: 10px;
    margin-right: 5px;
}

.filter__active {
    font-size: 18px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
    cursor: pointer;
}

.box {
    display: flex;
    width: 100%;
    padding: 40px 0;
    flex-direction: column;
}

.box2 {
    display: flex;
    justify-content: space-between;
    padding-bottom: 50px;
    border-bottom: 3px solid var(--dark);
    margin-bottom: 60px;
}

.box3 {
    padding: 10px 80px 10px 0;
    margin-right: 90px;
    border-right: 3px solid var(--dark);
}

.item2 {
    padding: 10px 80px 10px 0;
}

.p {
    font-family: "Gotham Book";
    font-size: 18px;
    height: 85px;
}

.p2 {
    font-weight: bold;
}

.p3 {
    color: var(--warning);
    cursor: pointer;
}

.p4 {
    color: var(--danger);
    cursor: pointer;
}

.title {
    font-size: 65px;
    line-height: 50px;
    text-align: center;
}

.title__ {
    font-size: 65px;
    line-height: 50px;
}

.title__small {
    color: var(--dark);
    font-size: 27px;
    line-height: 23px;
}

.title__small svg {
    color: var(--warning);
    width: 16.8px;
    height: 20px;
    margin-right: 10px;
}

.title__small2 {
    color: var(--dark);
    font-size: 27px;
    line-height: 23px;
}

.title__small2 svg {
    color: var(--danger);
    width: 16.8px;
    height: 20px;
    margin-right: 10px;
}

.title__small__ {
    font-size: 27px;
    line-height: 23px;
}

.margin {
    margin-bottom: 40px;
}

.right {
    display: flex;
}

.button {
    margin-top: 30px;
    background-color: var(--warning);
}

.p3 a,
.p4 a {
    text-decoration: none;
    color: inherit;
}

@media (max-width: 680px) {
    .sucursales {
        margin-top: -190px;
        padding-bottom: 0;
        max-width: 100vw;
        overflow: hidden;
    }
    .container {
        max-width: 100vw;
    }
    .mapInner {
        height: 147px;
    }
    .map {
        margin: 0 auto;
        width: 341px;
    }
    .filters {
        padding: 0 40px;
    }
    .filter,
    .filter__active {
        font-size: 13px;
    }
    .filter svg,
    .filter__active svg {
        font-size: 5px;
    }
    .title {
        font-size: 33px;
        line-height: 20px;
    }
    .title__small,
    .title__small2 {
        font-size: 15px;
        line-height: 20px;
    }
    .title__small svg,
    .title__small2 svg {
        width: 10px;
        height: 14px;
    }
    .p,
    .p2,
    .p3 {
        font-size: 13px;
        line-height: 13px;
        height: auto;
    }
    .box3 {
        padding: 0;
        margin-right: 0;
        border-right: 0;
    }
    .box {
        display: flex;
        width: 100%;
        padding: 0;
        flex-direction: column;
    }
    .box2 {
        display: flex;
        justify-content: space-between;
        padding-bottom: 0;
        border-bottom: 0;
        margin-bottom: 0px;
    }
    .box2,
    .right {
        flex-direction: column;
    }
    .item2,
    .item {
        padding: 20px 40px;
        border-bottom: 1px solid var(--dark);
    }
}
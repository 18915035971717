.home {
    background-image: url("../../assets/static/FondoHome_01.svg");
    background-repeat: repeat;
    background-position: center top;
    background-size: cover;
    position: relative;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    flex-direction: column;
    min-height: 757px;
    height: 757px;
    width: 100vw;
    max-width: 100vw;
    margin-bottom: 150px;
}

.container {
    width: 100%;
    position: relative;
    text-align: center;
    padding: 80px 0;
    margin-bottom: 60px;
    z-index: 1;
}

.title__white {
    color: var(--white) !important;
    position: relative;
}

.button {
    padding: 25px 25px;
    z-index: 1;
}

.button a {
    text-decoration: none;
    color: inherit;
}

.bg__woks {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: space-between;
}

.woks {
    position: absolute;
    top: -10%;
    left: -17%;
    transform: translateY(-50%);
}

.woks2 {
    position: absolute;
    top: -10%;
    right: 0%;
    transform: translateY(-50%);
    width: 315px;
    overflow: hidden;
}

.woks2 img {
    object-fit: contain;
}

.woks3 {
    display: none;
}

.ok {
    position: absolute;
    bottom: 0;
    right: 100px;
    transform: translateY(50%);
}

@media (max-width: 680px) {
    .home {
        min-height: 514px;
        margin-bottom: 0;
    }
    .container {
        padding: 78px 0;
        margin-bottom: 0;
    }
    .woks3 {
        width: 500px;
        max-width: 100vw;
        height: auto;
        top: 25%;
        position: absolute;
        left: 50%;
        display: block;
        transform: translate(-50%, -50%);
    }
    .woks3 img {
        width: 500px;
        max-width: 100vw;
        height: 500px;
        object-fit: contain;
    }
    .woks,
    .woks2,
    .ok {
        display: none;
    }
}
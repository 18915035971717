.title {
  font-size: 35px;
  line-height: 27px;
  color: var(--dark);
  text-align: center;
}

.title__ {
  font-size: 35px;
  line-height: 27px;
  text-align: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 50px 0;
}

.subtitle {
  font-size: 15px;
  width: 265px;
  font-family: "Gotham Book";
}

.circleRed {
  background-color: var(--danger);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  color: var(--white);
  font-size: 18px;
  font-family: "Monument Extended";
  margin-left: 15px;
  box-sizing: border-box;
}

.circleYellow {
  background-color: var(--warning);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  font-size: 45px;
  font-family: "Monument Extended";
  position: absolute;
  top: -40px;
  left: 10px;
}

.circleYellow_special {
  background-color: var(--warning);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  font-size: 45px;
  font-family: "Monument Extended";
  position: absolute;
  top: -40px;
  left: 20px;
}


.box {
  position: relative;
  border: 3px solid var(--dark);
  border-radius: 15px;
  padding: 15px;
  width: 100%;
  margin: 0 15px;
  box-sizing: border-box;
  background-color: var(--white);
}

.box_special {
  position: relative;
  border: 3px solid var(--dark);
  border-radius: 15px;
  padding: 15px;
  width: 100%;
  margin: 0 15px 0 0px;
  box-sizing: border-box;
  background-color: var(--white);
}

.boxTitle {
  font-family: "Gotham";
  font-size: 18px;
  color: var(--dark);
  text-transform: uppercase;
  font-weight: 600;
  margin-left: 65px;
  text-align: left;
}
.boxTitle2 {
  font-family: "Gotham";
  font-size: 18px;
  color: var(--dark);
  text-transform: uppercase;
  font-weight: 600;
  text-align: left;
}

.note {
  text-align: left;
  margin-left: 65px;
  font-size: 12px;
  padding-bottom: 10px;
}

.li {
  text-align: left;
  display: flex;
  align-items: center;
  padding-bottom: 5px;
  font-size: 12px;
}
.li_sepcial {
  text-align: left;
  display: flex;
  align-items: center;
  padding-bottom: 5px;
  font-size: 11px;
  margin-left: 45px;
}
.li_right {
  text-align: right;
  padding: 5px 0;
  font-size: 12px;
}
.li svg, .li_sepcial svg {
  margin-right: 10px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100vw;
  padding-top: 40px;
  box-sizing: border-box;
}

.special_row {
  position: absolute;
  width: 55vw;
  margin-left: 15px;
  height: 200px;
  left: 0;
  top: 45px;
  z-index: 1;
}
.special_row2 {
  position: absolute;

  margin-left: 15px;
  height: 200px;
  width: 55vw;
  right: 0;
  top: 70px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  width: 100%;
  padding: 0 15px;
  box-sizing: border-box;
}
.grid2 {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 20px;
  width: 100%;
  padding: 0 15px;
  box-sizing: border-box;
}

.double {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0;
  height: 300px;
  width: 100vw;
}

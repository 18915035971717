body {
    font-family: "Gotham", sans-serif;
    overflow-x: hidden;
}

:root {
    --primary: #e84926;
    --primaryScale1: #d9334d;
    --secondary: #292e2d;
    --success: #005641;
    --info: #18315a;
    --warning: #fecd2f;
    --danger: #e84926;
    --light: #ededed;
    --dark: #000;
    --white: #fff;
    --gray: #484b50;
    --gray-light: #e0e4e9;
    --background: #f5f7fa;
    --boxShadow: rgba(69, 90, 100, 0.08) 0px 1px 20px 0px;
    --boxShadowHover: rgba(69, 90, 100, 0.3) 0px 10px 20px 0px;
}

@keyframes rotateClockwise {
    100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
    }
}

.spinner {
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-name: rotateClockwise;
    animation-timing-function: linear;
}

@font-face {
    font-family: "Gotham";
    src: url("../fonts/Gotham-LightItalic.woff2") format("woff2"), url("../fonts/Gotham-LightItalic.woff") format("woff");
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Gotham";
    src: url("../fonts/Gotham-Light.woff2") format("woff2"), url("../fonts/Gotham-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gotham";
    src: url("../fonts/GothamLight.woff2") format("woff2"), url("../fonts/GothamLight.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gotham Book";
    src: url("../fonts/Gotham-BookItalic.woff2") format("woff2"), url("../fonts/Gotham-BookItalic.woff") format("woff");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Monument Extended";
    src: url("../fonts/MonumentExtended-Light.woff2") format("woff2"), url("../fonts/MonumentExtended-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gotham";
    src: url("../fonts/Gotham-BlackItalic.woff2") format("woff2"), url("../fonts/Gotham-BlackItalic.woff") format("woff");
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Gotham";
    src: url("../fonts/Gotham-BoldItalic.woff2") format("woff2"), url("../fonts/Gotham-BoldItalic.woff") format("woff");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Monument Extended";
    src: url("../fonts/MonumentExtended-Regular.woff2") format("woff2"), url("../fonts/MonumentExtended-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gotham";
    src: url("../fonts/Gotham-Bold.woff2") format("woff2"), url("../fonts/Gotham-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gotham";
    src: url("../fonts/Gotham-Medium.woff2") format("woff2"), url("../fonts/Gotham-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Monument Extended";
    src: url("../fonts/MonumentExtended-Ultrabold.woff2") format("woff2"), url("../fonts/MonumentExtended-Ultrabold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Monument Extended";
    src: url("../fonts/MonumentExtended-Black.woff2") format("woff2"), url("../fonts/MonumentExtended-Black.woff") format("woff");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gotham Book";
    src: url("../fonts/Gotham-Book.woff2") format("woff2"), url("../fonts/Gotham-Book.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gotham";
    src: url("../fonts/GothamLight.woff2") format("woff2"), url("../fonts/GothamLight.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

div#root main {
    overflow: hidden;
}
.home {
  background-image: url("../../assets/static/FondoHome_01.svg");
  background-repeat: repeat;
  background-position: center top;
  background-size: cover;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 857px;
  height: 857px;
  width: 100vw;
  box-sizing: border-box;
}
.container {
  width: 100%;
  position: relative;
  text-align: center;
  padding: 80px 0;
  margin-bottom: 60px;
  z-index: 1;
  box-sizing: border-box;
}
.title__white {
  color: var(--white) !important;
  position: relative;
}
.button {
  padding: 25px 25px;
  z-index: 1;
}

.button a {
  text-decoration: none !important;
  color: inherit;
}

.button:hover, .button a:hover{
  opacity: .7;
}

.bg__woks {
  position: relative;
  width: 100vw;
  max-width: 100vw;
  box-sizing: border-box;
  height: 100vh;
  display: flex;
  justify-content: space-between;
}
.woks {
  position: absolute;
  top: 40%;
  left: -17%;
  transform: translateY(-50%);
}
.woks2 {
  position: absolute;
  top: 40%;
  right: 0%;
  transform: translateY(-50%);
  width: 315px;
  overflow: hidden;
}

.woks2 img {
  object-fit: contain;
}

.woks3 {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ok {
  position: absolute;
  bottom: -200px;
  right: 100px;
}

.particle1 {
  height: 68px;
  width: 45px;
  position: absolute;
  left: 50%;
  top: -20px;
}

.particle2 {
  height: 68px;
  width: 45px;
  position: absolute;
  left: 70%;
  top: -20px;
}

.cloud {
  position: absolute;
  left: 0;
  z-index: -1;
}

@media (max-width: 680px) {
  .home {
    height: 514px;
    min-height: 514px;
  }
  .container {
    padding: 78px 0;
    margin-bottom: 0;
  }
  .woks3 {
    width: 500px;
    max-width: 100vw;
    height: 500px;
    top: 45%;
  }
  .woks3 img {
    width: 500px;
    max-width: 100vw;
    height: 500px;
    object-fit: contain;
  }
  .woks,
  .woks2,
  .ok {
    display: none;
  }
}

.embla__slide__img {
    position: relative;
    display: block;
    min-height: auto;
    min-width: 100%;
}

.embla__slide__image {
    text-align: center;
}

.embla__slide__image__ {
    margin-top: 20px;
}

.button__float {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    white-space: nowrap;
    z-index: 1;
    display: none;
    padding: 0 10px;
    font-size: 20px;
    font-weight: bold;
}

.embla__slide__img:hover>.embla__slide__image img {
    opacity: 0.5;
}

.embla__slide__img:hover button {
    display: block;
}

.h5 {
    font-family: "Monument Extended";
    font-size: 18px;
    color: var(--warning);
    text-align: center;
    font-weight: normal;
    margin: 0;
}

.p {
    font-family: "Gotham Book";
    font-size: 18px;
    text-align: center;
    color: var(--white);
}

.p_bold {
    font-weight: bold;
}

.title {
    font-size: 61px;
    line-height: 47px;
    color: var(--white);
    text-align: center;
}

.title__ {
    font-size: 61px;
    line-height: 47px;
    text-align: center;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-image: url("../../assets/static/FondoVerde.svg");
    background-position: center;
    background-size: cover;
    padding: 100px 0;
}

.button__alone {
    margin: 50px auto;
}

.subtitle {
    font-family: "Gotham Book";
    font-size: 27px;
    margin: 0;
}

.subtitle2 {
    font-family: "Monument Extended";
    font-weight: bold;
    font-size: 27px;
    margin: 40px 0;
}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    max-width: 1040px;
    gap: 45px 96px;
    align-items: flex-start;
    margin: 40px 0 0 0;
}

.bot {
    margin-top: -60px;
    margin-bottom: 75px;
}

.top {
    margin: 80px;
}

@media (max-width: 680px) {
    .grid {
        grid-template-columns: 1fr 1fr;
        max-width: 100vw;
        justify-content: center;
        align-items: center;
        max-width: 100vw;
        box-sizing: border-box;
        gap: 0;
        padding: 0 15px;
        margin-bottom: 50px;
    }
    .embla__slide__img {
        position: static;
        display: block;
        min-height: 270px;
    }
    .bot {
        margin-top: 50px;
    }
    .title {
        font-size: 35px;
        line-height: 27px;
    }
    .p {
        max-width: 90vw;
        font-size: 17px;
    }
    .title__ {
        font-size: 35px;
        line-height: 27px;
    }
    .subtitle {
        font-size: 15px;
        width: 265px;
        font-family: "Gotham Book";
    }
    .title__small__,
    .title__small {
        font-size: 28px;
        line-height: 22px;
    }
    .container {
        box-sizing: border-box;
    }
    .button__float {
        font-size: 8px;
        height: 30px;
    }
}
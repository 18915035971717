.embla {
    position: relative;
    margin: 100px auto;
}

.embla__viewport {
    overflow: hidden;
    width: 100%;
}

.embla__viewport.is-draggable {
    cursor: move;
    cursor: grab;
}

.embla__viewport.is-dragging {
    cursor: grabbing;
}

.embla__container {
    display: flex;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -webkit-tap-highlight-color: transparent;
    margin-left: -10px;
}

.embla__slide {
    position: relative;
    min-width: 915px;
    padding-left: 40px;
}

.embla__slide__inner {
    position: relative;
    overflow: hidden;
    height: 612px;
}

.embla__slide__img {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    width: auto;
    min-height: auto;
    min-width: 100%;
    max-width: none;
    transform: translate(-50%, -50%);
}

.embla__button {
    outline: 0;
    cursor: pointer;
    background-color: transparent;
    touch-action: manipulation;
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    border: 0;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    fill: #1bcacd;
    padding: 0;
}

.embla__button:disabled {
    cursor: default;
    opacity: 0.3;
}

.embla__button__svg {
    width: 100%;
    height: 100%;
}

.embla__button--prev {
    left: 27px;
}

.embla__button--next {
    right: 27px;
}

.arrows {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 50px;
    box-sizing: border-box;
}

.arrow:hover {
    opacity: 0.6;
    cursor: pointer;
}

.dots {
    display: flex;
    align-items: center;
}

.circle {
    margin-right: 15px;
    position: relative;
    height: 15px;
}

.circle::before {
    content: " ";
    background-color: var(--warning);
    height: 5px;
    width: 100%;
    color: var(--warning);
    position: absolute;
    bottom: -8px;
    left: 0;
}

.circle2 {
    margin-right: 15px;
}

@media (max-width: 680px) {
    .embla__slide__inner,
    .embla__slide__img {
        height: 270px;
        width: calc(100vw - 30px);
    }
    .embla__slide__img img {
        height: 270px;
        width: calc(100vw - 30px);
    }
    .embla__slide {
        min-width: 100vw;
    }
    .arrows {
        padding: 50px 15px;
    }
    .arrow,
    .arrow img {
        height: 10px;
        width: 20px;
    }
    .circle,
    .circle2 {
        height: 15px;
    }
}
.footer {
    background-color: var(--dark);
    color: var(--white);
    display: flex;
    justify-content: space-between;
    position: relative;
    border-bottom: 15px solid var(--warning);
    box-sizing: border-box;
    padding: 40px 50px 0 50px;
    overflow: hidden;
}

.undi_link {
    text-decoration: none;
    color: white;
    display: block;
    margin-top: 20px;
}

.container {
    grid-column: 2/3;
    display: grid;
    grid-template-columns: 30% 1fr 1fr 20%;
    gap: 20px;
}

.title {
    font-family: "Monument Extended";
    font-weight: 900;
    font-size: 18px;
    margin: 0;
}

.title__warning {
    font-family: "Monument Extended";
    font-weight: 900;
    font-size: 18px;
    color: var(--warning);
    margin: 0;
}

.iconSocial {
    display: inline-block;
    margin: 17px 0 17px 10px;
}

.info {
    text-align: right;
}

.p,
.p_under {
    margin: 0;
    font-weight: lighter;
    font-size: 12px;
    margin-bottom: 16px;
}

.p_under {
    text-decoration: underline;
}

.p_under a {
    text-decoration: none;
    color: var(--white);
}

.icon {
    position: absolute;
    left: 50%;
    bottom: -25px;
    transform: translateX(-50%);
}

@media (max-width: 1220px) {
    .footer {
        padding: 20px 40px 0 40px;
        border-bottom: 8.5px solid var(--warning);
    }
    .box {
        height: 56px;
        width: 66px;
    }
    .box img {
        height: 56px;
        width: 66px;
    }
    .icon {
        height: 135px;
        width: 59px;
    }
    .icon img {
        height: 135px;
        width: 59px;
    }
    .title,
    .title__warning {
        font-size: 9px;
    }
    .p,
    .p_under {
        font-size: 6px;
    }
    .iconSocial {
        display: inline-block;
        margin: 9px 0 19px 5px;
        height: 9.5px;
        width: 9.5px;
    }
    .iconSocial img {
        height: 9.5px;
        width: 9.5px;
    }
    .undi_link {
        font-size: 9px;
        margin-top: 10px;
    }
}
.home {
  position: relative;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 857px;
  height: 857px;
  width: 100vw;
  max-width: 100vw;
}

.container {
  width: 100%;
  position: relative;
  text-align: center;
  padding: 80px 0;
  margin-bottom: 60px;
  z-index: 1;
}
.title {
  color: var(--dark) !important;
  position: relative;
}
.button {
  padding: 25px 25px;
  z-index: 1;
}

.bg__woks {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-between;
}
.woks {
  position: absolute;
  top: 45%;
  left: -17%;
  transform: translateY(-50%);
}
.woks2 {
  position: absolute;
  top: 40%;
  right: 0%;
  transform: translateY(-50%);
  width: 315px;
  overflow: hidden;
}

.woks2 img {
  object-fit: contain;
}
.woks3 {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.particle1 {
  height: 68px;
  width: 45px;
  position: absolute;
  left: 50%;
  top: -20px;
}

.particle2 {
  height: 68px;
  width: 45px;
  position: absolute;
  left: 70%;
  top: -20px;
}
.ok {
  position: absolute;
  bottom: 80px;
  right: 100px;
  transform: translateY(50%);
}
.central {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
}
.img2 {
  position: absolute;
}
.img3 {
  position: absolute;
  right: 50%;
  top: 0%;
  transform: translate(-50%, -50%);
}
.img4 {
  position: absolute;
  left: 120%;
  top: 0%;
  transform: translate(-50%, -50%);
}

@media (max-width: 680px) {
  .home {
    height: 514px;
    min-height: 514px;
    max-width: 100vw;
    box-sizing: border-box;
  }
  .container {
    padding: 78px 0;
    margin-bottom: 0;
  }
  .woks3 {
    width: 400px;
    max-width: 100vw;
    height: 400px;
    top: 20%;
  }
  .woks,
  .woks2,
  .ok {
    display: none;
  }
  .img1 {
    height: 79px;
    width: 137px;
  }
  .img1 img {
    height: 79px;
    width: 137px;
  }
  .img2 {
    height: 125px;
    width: 6px;
  }
  .img2 img {
    height: 125px;
    width: 6px;
  }
  .img3 {
    width: 90px;
    height: 81px;
  }

  .img3 img {
    width: 90px;
    height: 81px;
  }
  .img4 {
    width: 137px;
    height: 106px;
  }
  .img4 img {
    width: 137px;
    height: 106px;
  }
}

.embla__slide__img {
    position: relative;
    display: block;
    width: 250px;
    height: 440px;
    min-height: auto;
    min-width: 100%;
    max-width: none;
}

.embla__slide__image {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 267px;
}

.embla__slide__image::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 70%;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0);
}

.button__float {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    white-space: nowrap;
    z-index: 1;
    display: none;
    padding: 0 10px;
    font-size: 20px;
    font-weight: bold;
}

.embla__slide__img:hover>.embla__slide__image img {
    opacity: 0.5;
}

.embla__slide__img:hover button {
    display: block;
}

.embla__button {
    outline: 0;
    cursor: pointer;
    background-color: transparent;
    touch-action: manipulation;
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    border: 0;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    fill: #1bcacd;
    padding: 0;
}

.h5 {
    font-family: "Monument Extended";
    font-size: 18px;
    color: var(--success);
    text-align: center;
    margin: 0;
}

.p {
    font-family: "Gotham Book";
    font-size: 18px;
    text-align: center;
}

.title {
    font-size: 61px;
    line-height: 47px;
    color: var(--dark);
    text-align: center;
}

.title__ {
    font-size: 61px;
    line-height: 47px;
    text-align: center;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-image: url("../../assets/static/FondoFullBox.svg");
    background-position: center top;
    background-size: contain;
    padding-bottom: 100px;
}

.button__alone {
    margin: 50px auto;
}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    max-width: 1040px;
    gap: 45px 96px;
    align-items: flex-start;
    margin: 75px 0 0 0;
}

.bot {
    margin: 85px;
    display: flex;
    text-align: left;
    align-items: flex-end;
}

.top {
    margin: 70px 0 178px 0;
}

.title__small {
    font-size: 46px;
    line-height: 36px;
}

.title__small__ {
    color: var(--dark);
    font-size: 46px;
    line-height: 36px;
}

.p__combo {
    font-weight: bold;
    line-height: 10px;
    margin: 0;
}

.p__combo:first-of-type {
    margin-top: 10px;
    line-height: 20px;
}

.combo {
    margin-left: 45px;
}

@media (max-width: 680px) {
    .grid {
        grid-template-columns: 1fr;
        margin-top: 40px;
        max-width: 100vw;
        box-sizing: border-box;
    }
    .embla__slide__img {
        min-height: 270px;
    }
    .title,
    .title__ {
        font-size: 30px;
        line-height: 65px;
    }
    .subtitle {
        display: none;
    }
    .container {
        box-sizing: border-box;
        max-width: 100vw;
    }
    .button__float {
        font-size: 8px;
        height: 30px;
    }
}
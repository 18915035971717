.contacto {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--dark);
  color: var(--white);
}
.container {
  max-width: 1080px;
  width: 1080px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 50px 0;
}
.title__white {
  font-size: 65px !important;
  line-height: 50px !important;
  color: var(--white) !important;
}
.title__white2 {
  font-size: 50px !important;
  line-height: 45px !important;
  color: var(--white) !important;
  text-transform: none !important;
  margin: 60px 0 !important;
}
.title {
  font-size: 65px !important;
  line-height: 50px !important;
}
.images {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}
.image {
  margin-bottom: -170px;
}
.image__float {
  position: absolute;
  bottom: 100px;
  right: 100px;
}
.subtitle {
  font-family: "Gotham Book";
  font-size: 27px;
  margin-top: 0;
}
.text {
  font-family: "Gotham Book";
  font-size: 18px;
}
.text__warning {
  font-size: 18px;
  color: var(--warning);
  font-weight: bold;
}
.text__warning_ {
  font-size: 18px;
  color: var(--warning);
  font-weight: bold;
  padding-bottom: 52px;
  margin-bottom: 52px;
  border-bottom: 3px solid var(--white);
  display: inline-block;
}
.hr {
  display: none;
}
@media (max-width: 680px) {
  .contacto{
    overflow: hidden;
  }
  .container {
    max-width: 100vw;
    width: 100vw;
    grid-template-columns: 1fr;
    margin: 50px 0 0 0;
    padding: 0 25px;
    box-sizing: border-box;
  }
  .title__white {
    font-size: 50px !important;
    line-height: 40px !important;
  }
  .title__white2 {
    font-size: 35px !important;
    line-height: 27px !important;
  }
  .title {
    font-size: 50px !important;
    line-height: 40px !important;
  }
  .images {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100vw;
  }
  .image {
    margin-bottom: 0;
  }
  .image img {
    object-fit: contain;
    width: 100vw;
    margin-left: -50px;
  }
  .image__float {
   display: none;
  }
  .subtitle {
    font-size: 15px;
  }
  .text {
    font-size: 13px;
  }
  .text__warning {
    font-size: 13px;
  }
  .text__warning_ {
    font-size: 13px;
    color: var(--warning);
    font-weight: bold;
    padding-bottom: 0px;
    margin-bottom: 0px;
    border-bottom: 0;
    display: inline-block;
  }
  .hr {
    display: block;
    margin-left: -25px;
    margin: 50px 0 50px -25px;
    color: var(--white);
    height: 1px;
    background-color: var(--white);
  }
}

.embla__slide__img {
    position: relative;
    display: block;
    width: 250px;
    min-height: auto;
    min-width: 100%;
    max-width: none;
}

.embla__slide__image {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 267px;
}

.embla__slide__image::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 70%;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0);
}

.button__float {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    white-space: nowrap;
    z-index: 1;
    display: none;
    padding: 0 10px;
    font-size: 20px;
    font-weight: bold;
}

.embla__slide__img:hover>.embla__slide__image img {
    opacity: 0.5;
}

.embla__slide__img:hover button {
    display: block;
}

.embla__button {
    outline: 0;
    cursor: pointer;
    background-color: transparent;
    touch-action: manipulation;
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    border: 0;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    fill: #1bcacd;
    padding: 0;
}

.h5 {
    font-family: "Monument Extended";
    font-size: 18px;
    color: var(--danger);
    text-align: center;
    margin: 0;
}

.p {
    font-family: "Gotham Book";
    font-size: 18px;
    text-align: center;
}

.title {
    font-size: 61px;
    line-height: 47px;
    color: var(--dark);
    text-align: center;
}

.title__ {
    font-size: 61px;
    line-height: 47px;
    text-align: center;
}

.title__red {
    font-size: 61px;
    line-height: 47px;
    text-align: center;
    color: var(--danger);
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 100px 0;
    position: relative;
}

.button__alone {
    margin: 50px auto;
}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    max-width: 1090px;
    gap: 15px 30px;
    align-items: flex-start;
    margin: 55px 0 0 0;
}

.bot {
    margin-top: -60px;
    margin-bottom: 75px;
}

.cookie {
    margin: 80px 0;
}

.subtitle {
    font-size: 27px;
    font-family: "Gotham Book";
}

.subtitle_bold {
    font-weight: bold;
    font-family: "Gotham";
}

.s1 {
    font-family: "Monument Extended";
    font-size: 18px;
    margin: 0;
}

.s2 {
    font-family: "Gotham Book";
    font-size: 18px;
}

.box {
    margin: 50px 0 25px 0;
    padding: 30px 55px;
    border-bottom: 3px solid var(--dark);
}

.box2 {
    margin-top: 25px;
}

.float {
    position: absolute;
    top: -70px;
    right: 50px;
}

.embla__slide__image img {
    object-fit: contain;
}

@media (max-width: 680px) {
    .container {
        width: 100vw;
        max-width: 100vw;
        overflow: hidden;
    }
    .grid {
        grid-template-columns: 1fr;
        margin-top: 40px;
        gap: 50px;
    }
    .title,
    .title__,
    .title__red {
        font-size: 50px;
        line-height: 38px;
    }
    .subtitle {
        font-size: 18px;
    }
    .cookie {
        max-width: 100vw;
        margin: 0;
    }
    .cookie img {
        max-width: 100vw;
        object-fit: contain;
    }
    .float {
        display: none;
    }
    .embla__slide__img {
        min-height: 270px;
    }
    .button__float {
        font-size: 8px;
        height: 30px;
    }
}
.lets {
    background-image: url("../../assets/static/FondoMoodBoard@2x.png");
    background-position: center;
    background-size: contain;
    background-repeat: repeat-x;
    position: relative;
    height: 750px;
    margin-bottom: 150px;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.image {
    position: absolute;
}

@media (max-width: 680px) {
    .lets {
        margin-bottom: 0;
        height: 200px;
    }
    .image img {
        max-width: 100vw;
        object-fit: cover;
        height: 200px;
    }
}
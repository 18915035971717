.embla__slide__img {
    position: relative;
    display: flex;
    width: 440px;
    min-height: auto;
    min-width: 100%;
    max-width: none;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.embla__slide__image {
    text-align: center;
}

.button__float {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 78%;
    z-index: 1;
    display: none;
}

.h5 {
    font-family: "Monument Extended";
    font-size: 18px;
    color: var(--warning);
    text-align: center;
    margin: 0;
    font-weight: normal;
}

.p {
    font-family: "Gotham Book";
    font-size: 18px;
    text-align: center;
    color: var(--white);
    max-width: 225px;
}

.title {
    font-size: 61px;
    line-height: 47px;
    color: var(--dark);
    text-align: center;
}

.title__ {
    font-size: 61px;
    line-height: 47px;
    text-align: center;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: var(--dark);
    padding: 100px 0;
    position: relative;
}

.subtitle {
    color: var(--white);
    font-family: "Gotham Book";
}

.button__alone {
    margin: 50px auto;
}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 800px;
    align-items: flex-start;
    gap: 50px 0;
}

.bot {
    margin-top: -60px;
    margin-bottom: 75px;
}

.float {
    position: absolute;
    top: -50px;
    right: 50px;
}

@media (max-width: 680px) {
    .grid {
        grid-template-columns: 1fr;
        margin-top: 40px;
        max-width: 100vw;
        box-sizing: border-box;
    }
    .embla__slide__img {
        min-height: 270px;
    }
    .float {
        display: none;
    }
    .title__ {
        font-size: 50px;
        line-height: 65px;
    }
    .subtitle {
        font-size: 15px;
        margin: 0;
    }
    .container {
        box-sizing: border-box;
        max-width: 100vw;
    }
}
.embla {
    position: relative;
}

.embla__viewport {
    overflow: hidden;
    width: 100%;
}

.embla__viewport.is-draggable {
    cursor: move;
    cursor: grab;
}

.embla__viewport.is-dragging {
    cursor: grabbing;
}

.embla__container {
    display: flex;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -webkit-tap-highlight-color: transparent;
    margin-left: -10px;
}

.link {
    text-decoration: none;
}

.embla__slide {
    position: relative;
    min-width: 250px;
    padding: 0 50px;
}

.arrow:hover {
    opacity: 0.6;
    cursor: pointer;
}

.embla__slide__inner {
    position: relative;
    overflow: hidden;
    height: 390px;
}

.embla__slide__img {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    width: auto;
    min-height: auto;
    min-width: 100%;
    max-width: none;
    transform: translate(-50%, -50%);
}

.embla__slide__image {
    text-align: center;
}

.embla__slide__image::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 70%;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0);
}

.button__float {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    white-space: nowrap;
    z-index: 1;
    display: block;
    opacity: 0;
    padding: 0 20px;
    font-size: 20px;
    font-weight: bold;
    transition: 0.5s all;
}

.embla__slide__img:hover>.embla__slide__image img {
    opacity: 0.5;
    transition: 0.5s all;
}

.embla__slide__img:hover button {
    opacity: 1;
    transition: 0.5s all;
}

.embla__button {
    outline: 0;
    cursor: pointer;
    background-color: transparent;
    touch-action: manipulation;
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    border: 0;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    fill: #1bcacd;
    padding: 0;
}

.embla__button:disabled {
    cursor: default;
    opacity: 0.3;
}

.embla__button__svg {
    width: 100%;
    height: 100%;
}

.embla__button--prev {
    left: 27px;
}

.embla__button--next {
    right: 27px;
}

.arrows {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 50px;
    box-sizing: border-box;
}

.dots {
    display: flex;
    align-items: center;
}

.circle {
    margin-right: 15px;
    position: relative;
    height: 15px;
}

.circle::before {
    content: " ";
    background-color: var(--warning);
    height: 5px;
    width: 100%;
    color: var(--warning);
    position: absolute;
    bottom: -8px;
    left: 0;
}

.circle2 {
    margin-right: 15px;
}

.h5 {
    font-family: "Monument Extended";
    font-size: 18px;
    color: var(--success);
    text-align: center;
    margin: 0;
}

.p {
    font-family: "Gotham Book";
    font-size: 18px;
    text-align: center;
}

.title {
    font-size: 61px;
    line-height: 47px;
    color: var(--dark);
    text-align: center;
}

.title__ {
    font-size: 61px;
    line-height: 47px;
    text-align: center;
    margin-bottom: 80px;
}

.container {
    margin: 100px 0;
}

.button__alone {
    margin: 50px auto;
}

@media (max-width: 680px) {
    .embla__slide__inner,
    .embla__slide__img {
        height: 233px;
        width: 122px;
    }
    .embla__slide {
        min-width: 122px;
        padding: 0 35px;
    }
    .arrows {
        padding: 50px 15px;
    }
    .arrow,
    .arrow img {
        height: 10px;
        width: 20px;
    }
    .circle,
    .circle2 {
        height: 15px;
    }
    .title,
    .title__ {
        font-size: 33px;
        line-height: 25px;
    }
    .title__ {
        margin-bottom: 40px;
    }
    .button__float {
        font-size: 8px;
        height: 30px;
    }
    .h5,
    .p {
        font-size: 10px;
    }
    .button__alone {
        margin: 10px auto;
    }
    .embla__slide__image img {
        height: 115px;
        width: 122px;
        object-fit: contain;
    }
}
.about {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  padding: 300px 0 0 0;
}
.container {
  max-width: 650px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.p {
  font-family: "Gotham Book";
  font-size: 27px;
  margin-bottom: 0;
}
.p span {
  font-family: "Gotham";
  font-size: 27px;
  font-weight: bold;
}
.buttons {
  display: flex;
  margin: 50px;
}

.buttons a {
  text-decoration: none;
}

.title {
  color: var(--dark);
}
.margin {
  margin-bottom: 40px;
}
.image {
  position: relative;
}
.neon1 {
  position: absolute;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  object-fit: contain;
}
.neon2 {
  object-fit: contain;
}
@media (max-width: 680px) {
  .about {
    padding-top: 180px;
    max-width: 100vw;
  }
  .title,
  .margin {
    font-size: 33px;
    line-height: 25px;
  }
  .p {
    font-size: 15px;
    max-width: 272px;
  }
  .p span {
    font-size: 15px;
    max-width: 272px;
  }
  .image {
    width: 278px;
  }
  .image img {
    width: 278px;
    object-fit: contain;
  }
}

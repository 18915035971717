.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999999999;
  background-color: var(--dark);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-out;
  animation: changeBg 2s infinite;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 1;
}

.container__hide {
  opacity: 0;
  transition: all 0.5s ease-out;
  z-index: -1;
}

.tainsition {
  transition: all 0.5s ease-out;
}

@keyframes changeBg {
  0%,
  100% {
    background-image: url("../../assets/static/RockNWok_Preloader_01.svg");
  }
  25% {
    background-image: url("../../assets/static/RockNWok_Preloader_02.svg");
  }
  35% {
    background-image: url("../../assets/static/RockNWok_Preloader_03.svg");
  }
  45% {
    background-image: url("../../assets/static/RockNWok_Preloader_04.svg");
  }
  55% {
    background-image: url("../../assets/static/RockNWok_Preloader_05.svg");
  }
  65% {
    background-image: url("../../assets/static/RockNWok_Preloader_06.svg");
  }
  75% {
    background-image: url("../../assets/static/RockNWok_Preloader_07.svg");
  }
}

.rockwithus {
    background-color: var(--dark);
    position: relative;
    max-width: 100vw;
    width: 100vw;
    overflow: hidden;
    padding-top: 15px;
}

.text {
    text-align: center;
    position: relative;
    z-index: 1;
}

.title__ {
    color: var(--white);
}

.maxWrapper {}

.img1 {
    position: absolute;
    right: 50%;
    transform: translateX(50%);
    top: -60px;
    z-index: 0;
    margin-right: -340px;
}

.box2 {
    top: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 130px 0 120px;
    position: relative;
    max-width: 1280px;
    align-self: center;
    margin: 220px auto 0;
}

.img3 {
    position: absolute;
    top: 0;
}

.img2 {
    position: absolute;
    top: -120px;
    z-index: 1;
    left: 0;
}

.img4 {
    position: absolute;
    top: 380px;
    right: 0px;
}

.float {
    position: absolute;
    top: 150px;
    right: 30px;
}

.text2 {
    margin-top: 800px;
    text-align: center;
    position: relative;
    z-index: 2;
}

.box3 {
    display: flex;
    justify-content: center;
    position: relative;
    height: 610px;
    max-width: 1280px;
    margin: -95px auto 0;
}

.img5 {
    top: 0;
    position: absolute;
    z-index: 1;
    left: 60px;
}

.img6 {
    top: 60px;
    position: absolute;
    right: 60px;
}

.float2 {
    position: absolute;
    bottom: 0;
    z-index: 1;
    margin-left: 1000px;
}

.box4 {
    margin-top: 100px;
    position: relative;
    display: flex;
    justify-content: center;
    height: 1050px;
    max-width: 1280px;
    margin: 100px auto 0;
}

.img7 {
    position: absolute;
    top: 0;
    left: 60px;
    z-index: 1;
}

.img8 {
    position: absolute;
    top: 170px;
    right: 60px;
}

.float3 {
    position: absolute;
    z-index: 1;
    top: -120px;
    left: 80px;
}

@media (max-width: 680px) {
    .title,
    .title__ {
        font-size: 33px;
        line-height: 25px;
    }
    .img1 {
        height: 201px;
        width: 323px;
        margin-right: 0;
    }
    .img1 img {
        height: 201px;
        width: 323px;
        object-fit: contain;
    }
    .img2 {
        height: 68px;
        width: 101px;
        margin-left: 0;
        left: 5%;
    }
    .img2 img {
        height: 68px;
        width: 101px;
        object-fit: contain;
    }
    .img3 {
        height: 158px;
        width: 259px;
    }
    .img3 img {
        height: 158px;
        width: 259px;
        object-fit: contain;
    }
    .float {
        top: 50px;
        margin-right: 0;
        right: 5%;
        height: 30px;
        width: 30px;
    }
    .float img {
        object-fit: contain;
        height: 30px;
        width: 30px;
    }
    .box2 {
        padding: 40px 0 40px;
    }
    .img4 {
        height: 75px;
        width: 132px;
        margin-right: 0;
        right: 5%;
    }
    .img4 img {
        height: 75px;
        width: 132px;
        object-fit: contain;
    }
    .text2 {
        margin-top: 70px;
    }
    .box3 {
        display: flex;
        justify-content: center;
        margin-top: -95px;
        position: relative;
        height: 250px;
    }
    .img5 {
        top: 80px;
        margin-right: 0;
        height: 106px;
        width: 45vw;
        left: 5%;
    }
    .img5 img {
        height: 106px;
        width: 45vw;
        object-fit: contain;
    }
    .img6 {
        top: 110px;
        position: absolute;
        margin-left: 0;
        height: 142px;
        width: 55vw;
        right: 5%;
    }
    .img6 img {
        height: 142px;
        width: 55vw;
        object-fit: contain;
    }
    .float2 {
        height: 35px;
        width: 35px;
        bottom: -25px;
        right: 5%;
        -webkit-animation: spin 10s linear infinite;
        -moz-animation: spin 10s linear infinite;
        animation: spin 10s linear infinite !important;
    }
    .float2 img {
        object-fit: contain;
        height: 35px;
        width: 35px;
        position: absolute;
        -webkit-animation: spin 10s linear infinite;
        -moz-animation: spin 10s linear infinite;
        animation: spin 10s linear infinite !important;
    }
    .box4 {
        margin-top: 70px;
        height: 400px;
    }
    .img7 {
        width: 50vw;
        height: 113px;
        top: 0;
        margin-right: 0;
        left: 5%;
    }
    .img7 img {
        width: 50vw;
        height: 113px;
        object-fit: contain;
    }
    .img8 {
        width: 50vw;
        height: 115px;
        top: 45px;
        margin-left: 0;
        right: 5%;
    }
    .img8 img {
        width: 50vw;
        height: 115px;
        object-fit: contain;
    }
    .float3 {
        width: 34px;
        height: 51px;
        top: -40px;
        margin-right: 0;
        left: 5%;
    }
    .float3 img {
        width: 34px;
        height: 51px;
        object-fit: contain;
    }
}
.cocinamos {
  background-image: url("../../assets/static/FondoTextura.svg");
  background-position: center;
  background-size: cover;
  background-repeat: repeat-y;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -5px;
  position: relative;
  background-color: var(--dark);
  min-height: 1000px;
}
.image {
  position: absolute;
}
.float {
  position: absolute;
  top: -65px;
  right: 50px;
}
.text {
  z-index: 1;
  text-align: center;
}
.title1 {
  position: relative;
  z-index: 4;
}
.title {
  position: relative;
  z-index: 3;
}
.title__ {
  color: var(--white);
  position: relative;
  z-index: 2;
}
.title__:last-child {
  color: var(--white);
  position: relative;
  z-index: 1;
}
.title__,
.title,
.title1 {
  text-shadow: 0px 8px 11px rgba(0, 0, 0, 1);
}

@media (max-width: 680px) {
  .cocinamos {
    min-height: 500px;
    max-width: 100vw;
    box-sizing: border-box;
  }
  .image {
    width: 207px;
    height: 320px;
  }
  .image img {
    width: 207px;
    height: 320px;
    object-fit: contain;
  }
  .float {
    height: 67px;
    width: 40px;
    right: 25px;
    top: -30px;
  }
  .float img {
    height: 67px;
    width: 40px;
  }
  .title__,
  .title,
  .title1 {
    font-size: 33px;
    line-height: 26px;
    text-shadow: 0px 1px 11px rgba(0, 0, 0, 1);
  }
}

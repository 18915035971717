.header {
    display: grid;
    grid-template-columns: 1fr;
    width: 100vw;
    align-items: center;
    background-color: var(--dark);
    padding: 12px 35px;
    box-sizing: border-box;
    z-index: 9999999;
    transition: all 0.5s ease-in-out;
    position: sticky;
}

.fixed {
    position: fixed !important;
    transition: all 0.5s ease-in-out !important;
}

.opacity {
    opacity: 0;
    transition: all 0.5s ease-in-out !important;
}

.opacity2 {
    opacity: 1;
    transition: all 0.5s ease-in-out !important;
}

.header__white {
    display: grid;
    grid-template-columns: 1fr;
    position: sticky;
    width: 100vw;
    align-items: center;
    background-color: var(--white);
    padding: 12px 35px;
    box-sizing: border-box;
    z-index: 9999999;
    transition: all 0.5s ease-in-out;
}

.header__container {
    grid-column: 1;
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
}

.header__nav {
    display: flex;
    justify-content: space-between;
}

.header__buttons {
    display: flex;
}

.button {
    margin-left: 20px !important;
    border-radius: 10px !important;
    font-weight: bold !important;
}

.button__warning {
    background-color: var(--warning) !important;
    color: var(--dark) !important;
    border-radius: 10px !important;
    font-weight: bold !important;
}

.button__warning a {
    color: var(--dark) !important;
    text-decoration: none !important;
}

.button a {
    text-decoration: none !important;
    color: inherit;
}

.button:hover,
.button a:hover {
    opacity: .7;
}

.header__menuMobile,
.header__icon_menuMobile {
    display: none;
}

.header__menu {
    list-style: none;
    padding: 0;
    display: flex;
}

.header__item,
.header__item__white {
    padding: 0 20px;
}

.header__item a {
    color: var(--white);
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    padding: 0 15px;
    position: relative;
}

.header__item a::before,
.header__item__white a::before {
    position: absolute;
    bottom: -51px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 5px;
    border-radius: 0;
    content: "";
    background-color: var(--warning);
    opacity: 1;
    transition: all 200ms linear;
}

.header__item:hover>a::before,
.header__item__white:hover>a::before {
    opacity: 1;
    width: 100%;
}

.header__item__white a {
    color: var(--dark);
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    padding: 0 15px;
    position: relative;
}

.header__buttons__mobile {
    display: none;
}

.iconClose {
    display: none;
}

@media (max-width: 1220px) {
    .header,
    .header__white {
        position: relative;
        z-index: 9999999;
        padding: 5px 12px;
    }
    .header__container {
        padding: 15px;
        position: relative;
        z-index: 9999999;
    }
    .header__menuMobile,
    .header__icon_menuMobile {
        display: flex;
        color: var(--white);
    }
    .header__icon_menuMobile {
        margin-top: 45px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .header__nav {
        transform: translateX(-100%);
        width: 250px;
        position: fixed;
        transition: all 0.3s linear 0ms;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        flex-direction: column;
        height: 100%;
        background-color: var(--white);
        padding: 10px 15px;
        box-sizing: border-box;
        z-index: 9999999;
    }
    .header__nav__active {
        transform: translateX(0%);
        width: 100vw;
        position: fixed;
        z-index: 9999999;
        transition: all 0.3s linear 0ms;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        flex-direction: column;
        height: 100%;
        padding: 10px 15px;
        box-sizing: border-box;
        background-color: var(--dark);
    }
    .header__menu {
        flex-direction: column;
        padding-top: 25px;
        padding-bottom: 25px;
        margin: 0;
        width: 100%;
    }
    .header__item,
    .header__item__white {
        padding: 20px;
        text-align: center;
    }
    .header__item a,
    .header__item__white a {
        color: var(--white);
        font-size: 18px;
    }
    .header__buttons__mobile {
        display: block;
    }
    .header__buttons {
        display: none;
    }
    .header__container img {
        height: 38px;
        width: 157px;
    }
    .header__menuMobile img {
        height: 24px;
        width: 24px;
    }
    .iconClose {
        width: 50px !important;
        height: 50px !important;
        object-fit: contain;
        margin-top: 20px;
        display: block;
    }
    .header__buttons__mobile {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }
    .button {
        margin: 35px 15px;
    }
    .imagebottom {
        padding-bottom: 50px;
        border-bottom: 3px solid var(--white);
    }
    .iconSocial img {
        width: 27px !important;
        height: 27px !important;
        margin: 35px 15px;
    }
}
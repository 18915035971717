.title {
  font-family: "Monument Extended";
  text-transform: uppercase;
  font-weight: bold;
  font-size: 85px;
  color: var(--warning);
  margin: 0;
  line-height: 65px;
  padding: 0;
}

@media (max-width: 680px) {
  .title {
    font-size: 50px;
    line-height: 42px;
  }
}

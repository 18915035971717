.privacy {
    width: 100vw;
    max-width: 1040px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    margin: 50px auto;
}

.title__dark {
    font-size: 65px !important;
    line-height: 50px !important;
    color: var(--dark) !important;
    width: 100%;
    box-sizing: border-box !important;
}

.title {
    font-size: 65px !important;
    line-height: 50px !important;
    width: 100%;
    box-sizing: border-box !important;
}

.text {
    font-family: "Gotham Book";
    font-size: 18px;
    width: 100%;
    line-height: 22px;
    box-sizing: border-box !important;
}

strong {
    font-family: "Gotham";
    font-weight: bold;
}

.text ul {
    padding: 0;
    list-style: none;
}

@media (max-width: 680px) {
    .privacy {
        width: 100vw;
        max-width: 100vw;
        padding: 50px 25px;
        margin: 0;
        box-sizing: border-box;
    }
    .title__dark,
    .title {
        font-size: 45px !important;
        line-height: 35px !important;
    }
    .text {
        max-width: 90vw;
        box-sizing: border-box !important;
        font-size: 13px;
        line-height: 15px;
    }
}
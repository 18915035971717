.map {
  height: 100%;
  width: 100%;
}

.pin {
  height: 50px;
  width: 50px;
  object-position: center;
  object-fit: contain;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.infoMap {
  background-color: #fff !important;
  font-family: "Monument Extended";
  padding: 15px;
  z-index: 1000000;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -100%);
  text-align: center;
  font-size: 18px;
  text-transform: uppercase;
}

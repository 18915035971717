.about {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    padding: 100px 0;
}

.container {
    max-width: 850px;
    width: 100vw;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.box {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    padding: 100px 0;
}

.p {
    font-family: "Gotham Book";
    font-size: 18px;
}

.title {
    color: var(--dark);
    font-size: 65px;
    line-height: 50px;
}

.item a {
    text-decoration: none;
}

.title__ {
    font-size: 65px;
    line-height: 50px;
}

.title__small {
    color: var(--dark);
    font-size: 27px;
    line-height: 23px;
}

.title__small__ {
    font-size: 27px;
    line-height: 23px;
}

.margin {
    margin-bottom: 40px;
}

.right {
    border-left: 3px solid var(--dark);
    padding-left: 110px;
}

.item {
    height: 175px;
    padding-top: 70px;
}

.item:first-child {
    padding-top: 0;
}

.item:last-child {
    height: auto;
}

.button {
    margin-top: 30px;
    background-color: var(--warning);
}

@media (max-width: 680px) {
    .about {
        padding: 0;
        padding-left: 45px;
        align-items: flex-start;
        box-sizing: border-box;
    }
    .container {
        max-width: 240px;
        width: 240px;
    }
    .title,
    .title__ {
        font-size: 33px;
        line-height: 25px;
    }
    .box {
        grid-template-columns: 1fr;
        padding: 34px 0;
    }
    .title__small,
    .title__small__ {
        font-size: 15px;
        line-height: 11.5px;
    }
    .p {
        font-size: 13px;
    }
    .item {
        padding-top: 21px;
        height: auto;
    }
    .right {
        border-left: 0;
        padding-left: 0;
    }
    .item:last-child {
        padding-bottom: 21px;
    }
    .button {
        margin-top: 0px;
    }
}
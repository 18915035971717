.reviews {
    display: flex;
    position: relative;
    justify-content: center;
    width: 100vw;
    overflow: hidden;
}

.particles {
    position: absolute;
}

.review {
    position: absolute;
    width: 232px;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 40px;
    left: 200px;
    top: 50%;
    padding: 1.5rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
    line-height: 20px;
    box-shadow: var(--boxShadow);
}

.review2 {
    position: absolute;
    width: 300px;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 40px;
    left: 50%;
    top: 15%;
    transform: translateX(-50%);
    padding: 1.5rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
    line-height: 20px;
    box-shadow: var(--boxShadow);
}

.review3 {
    position: absolute;
    width: 200px;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 40px;
    right: 8%;
    top: 50%;
    transform: translateX(-50%);
    padding: 1.5rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
    line-height: 20px;
    box-shadow: var(--boxShadow);
}

.p {
    margin-bottom: 10px;
}

.stars {
    margin-bottom: 10px;
}

.user {
    position: absolute;
    bottom: -40%;
}

.user2 {
    position: absolute;
    bottom: -50%;
}

@media (max-width: 680px) {
    .reviews {
        overflow: hidden;
    }
    .review,
    .review3 {
        display: none;
    }
    .review2 {
        width: 200px;
        border-radius: 10px;
        left: 50%;
        top: -5%;
        transform: translateX(-50%);
        padding: 1rem;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-weight: bold;
        line-height: 20px;
        box-shadow: var(--boxShadow);
    }
    .user2 {
        bottom: -68%;
        width: 125px;
    }
    .user2 img {
        width: 125px;
        object-fit: contain;
    }
    .p {
        font-size: 11px;
        line-height: 12px;
    }
    .image img {
        width: 100vw;
        height: auto;
        object-fit: contain;
    }
    .particles img {
        width: 100vw;
        height: auto;
        object-fit: contain;
    }
}
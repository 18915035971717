.lets {
    width: calc(100vw - 60px);
    padding-left: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.container {
    max-width: 1280px;
    width: 1280px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 100px 0;
}

.title__dark {
    font-size: 65px;
    line-height: 50px;
    color: var(--dark);
}

.title {
    font-size: 65px;
    line-height: 50px;
}

.subtitle,
.subtitle2 {
    font-size: 27px;
    font-weight: bold;
}

.subtitle2 {
    position: relative;
    z-index: 1;
}

.ul {
    list-style: none;
    padding-left: 1rem;
    font-family: "Gotham Book";
    font-size: 18px;
}

.subtitle2::after {
    position: absolute;
    content: "";
    width: 194px;
    height: 14px;
    background-color: var(--warning);
    bottom: 0;
    left: -5px;
    z-index: -1;
}

.images {
    display: flex;
    position: relative;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
}

.image {
    margin-bottom: -170px;
}

.image__float {
    position: absolute;
    bottom: 100px;
    right: 100px;
}

@media (max-width: 1200px) {
    .container {
        margin-top: 0;
        max-width: 100vw;
        width: 100vw;
        grid-template-columns: 1fr;
    }
}

@media (max-width: 680px) {
    .let {
        max-width: 100vw;
        box-sizing: border-box;
        width: 100vw;
    }
    .lets {
        width: 100vw;
        padding-left: 0;
    }
    .container {
        overflow: hidden;
        margin-top: 0;
        box-sizing: border-box;
        max-width: 100vw;
        width: 100vw;
        grid-template-columns: 1fr;
    }
    .title__dark,
    .title {
        font-size: 33px;
        line-height: 25px;
    }
    .subtitle,
    .subtitle2 {
        font-size: 15px;
    }
    .ul {
        font-size: 15px;
        padding-left: 0;
    }
    .text {
        padding-left: 50px;
        padding-right: 15px;
        box-sizing: border-box max-width 100%;
    }
    .subtitle2::after {
        width: 106px;
        height: 7px;
    }
    .images {
        box-sizing: border-box;
    }
    .image__float {
        bottom: 0px;
        right: 40px;
        width: 41px;
        height: 68px;
        z-index: 1111;
    }
    .image__float img {
        z-index: 1111;
        width: 41px;
        height: 68px;
    }
    .image {
        height: auto;
        padding-bottom: 100px;
    }
    .image img {
        max-width: 100vw;
        height: 400px;
        text-align: center;
        object-fit: contain;
    }
}
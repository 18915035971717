.button {
  font-size: 1rem;
  display: flex;
  align-items: center;
  color: #fff;
  padding: 12px 30px;
  border-radius: 100px;
  position: relative;
  overflow: hidden;
  transition: all 100ms linear;
  box-sizing: border-box;
  outline: none;
  -moz-osx-font-smoothing: grayscale;
  cursor: pointer;
  border: 0;
  text-transform: uppercase;
  height: 50px;
  background-color: var(--primary);
}
.button svg {
  margin-left: 5px;
}
.button:hover {
  opacity: 0.7;
}

.embla__slide__image {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 267px;
}

.button__float {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    white-space: nowrap;
    z-index: 1;
    display: none;
    padding: 0 10px;
    font-size: 20px;
    font-weight: bold;
}

.embla__slide__img {
    height: 440px;
}

.embla__slide__img:hover>.embla__slide__image img {
    opacity: 0.5;
}

.embla__slide__img:hover button {
    display: block;
}

.h5 {
    font-family: "Monument Extended";
    font-size: 18px;
    color: var(--success);
    text-align: center;
    margin: 0;
}

.p {
    font-family: "Gotham Book";
    font-size: 18px;
    text-align: center;
}

.title {
    font-size: 61px;
    line-height: 47px;
    color: var(--dark);
    text-align: center;
}

.title__ {
    font-size: 61px;
    line-height: 47px;
    text-align: center;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.button__alone {
    margin: 50px auto;
}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    max-width: 1040px;
    gap: 45px 96px;
    align-items: flex-start;
    margin: 75px 0 0 0;
}

.bot {
    margin: 85px;
    display: flex;
    text-align: left;
    align-items: flex-end;
}

.top {
    margin: 70px 0 178px 0;
}

.title__small {
    font-size: 46px;
    line-height: 36px;
}

.title__small__ {
    color: var(--dark);
    font-size: 46px;
    line-height: 36px;
}

.p__combo {
    font-weight: bold;
    line-height: 10px;
    margin: 0;
}

.p__combo:first-of-type {
    margin-top: 10px;
    line-height: 20px;
}

.combo {
    margin-left: 45px;
}

@media (max-width: 680px) {
    .container {
        max-width: 100vw;
        width: 100vw;
        overflow: hidden;
    }
    .grid {
        grid-template-columns: 1fr;
        margin-top: 40px;
        max-width: 100;
        box-sizing: border-box;
    }
    .title {
        font-size: 35px;
        line-height: 27px;
    }
    .p {
        max-width: 90vw;
    }
    .title__ {
        font-size: 35px;
        line-height: 27px;
    }
    .subtitle {
        font-size: 15px;
        width: 265px;
        font-family: "Gotham Book";
    }
    .title__small__,
    .title__small {
        font-size: 28px;
        line-height: 22px;
    }
    .bot {
        margin: 100px 0 60px 0;
        width: 310px;
        height: 110px;
    }
    .bot img {
        height: 170px;
    }
    .combo {
        margin-left: 20px;
    }
    .p__combo {
        font-size: 12px;
    }
    .button__float {
        font-size: 8px;
        height: 30px;
    }
}